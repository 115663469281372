(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1380,
    "normal": 1600
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/spinner-calc.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/tabs.js');

require('./modules/spoilers.js');

require('./modules/galleries.js');

require('./modules/sliders.js');

require('./modules/history.js');

require('./modules/compare.js');

require('./modules/order.js');

require('./modules/layout.js');

require('./modules/card-choose-offer.js');

require('./modules/brands.js');

require('./modules/quiz.js');

},{"./modules/brands.js":3,"./modules/card-choose-offer.js":4,"./modules/compare.js":5,"./modules/forms.js":6,"./modules/galleries.js":7,"./modules/history.js":10,"./modules/layout.js":11,"./modules/masks.js":12,"./modules/menus.js":13,"./modules/modals.js":14,"./modules/order.js":15,"./modules/quiz.js":16,"./modules/scroll.js":17,"./modules/sliders.js":18,"./modules/spinner-calc.js":19,"./modules/spinner.js":20,"./modules/spoilers.js":21,"./modules/tabs.js":22}],3:[function(require,module,exports){
'use strict';

$('.js-alphabet-filter').each(function () {
  var $filter = $(this);
  var alphabet = $.map($('[data-letter]'), function (item) {
    return $(item).data('letter');
  });

  alphabet.forEach(function (letter) {
    $filter.append($('<a class="item">' + letter + '</a>').click(function () {
      var $letter = $(this);
      var $group = $('[data-letter=' + letter + ']');
      $letter.toggleClass('is-active').siblings().removeClass('is-active');
      if ($letter.hasClass('is-active')) {
        $group.stop(true, true).fadeIn().siblings().hide();
      } else {
        $group.add($group.siblings()).stop(true, true).fadeIn();
      }
    }));
  });
});

$('.js-country-filter').each(function () {
  var $filter = $(this);
  var countriesList = $.map($('[data-country]'), function (item) {
    return $(item).data('country');
  }).filter(onlyUnique).filter(notEmpty);

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function notEmpty(value, index, self) {
    return value != '';
  }

  countriesList.forEach(function (country) {
    $filter.append($('<a class="item">' + country + '</a>').click(function () {
      var $country = $(this);
      var $group = $('[data-country=' + country + ']');
      $country.toggleClass('is-active');
      if ($filter.find('.is-active').length) {
        $('[data-country]').hide();
        $filter.find('.is-active').each(function () {
          var country = $(this).text();
          $('[data-country="' + country + '"]').stop(true, true).fadeIn();
        });
        hideEmptyGroups();
      } else {
        if ($('.js-alphabet-filter').find('.is-active').length) {
          $('.js-alphabet-filter').find('.is-active').each(function () {
            $('[data-letter="' + $(this).text() + '"]').stop(true, true).show();
            $('[data-country]').stop(true, true).show();
          });
        } else {
          $('[data-letter]').add('[data-country]').stop(true, true).show();
        }
      }
    }));
  });

  function hideEmptyGroups() {
    if ($('.js-alphabet-filter').find('.is-active').length) {
      $('.js-alphabet-filter').find('.is-active').each(function () {
        $('[data-letter="' + $(this).text() + '"]').stop(true, true).show();
      });
    } else {
      $('[data-letter]').stop(true, true).show();
    }
    $('[data-letter]').each(function () {
      var $group = $(this);
      if ($group.find('li:visible').length) {
        $group.show();
      } else {
        $group.hide();
      }
    });
  }
});

},{}],4:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./vue-components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./vue-components/OffersListComponent.js');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */
window.initPriceApp = function () {
  var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#js-card-price-app';

  if (!$(selector).length) return;

  var cardPriceApp = new Vue({
    el: selector,
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default
    },
    data: {
      offer: {},
      targetButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
        // $(this.$el).find('select.js-init-later').selectric({
        //   disableOnMobile: false,
        //   nativeOnMobile: true,
        // });
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {
        var data = $(this.targetButton).data();
        $.extend(data, val.basketData);
        $(this.targetButton).data(data);
      }
    }
  });
};

window.initPriceApp('#' + $('.js-card-price-app').attr('id'));

},{"./vue-components/OffersListComponent.js":23,"./vue-components/PriceComponent.js":24}],5:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

(function () {

  var $table = $('.js-compare-table');

  if (!$table.length) return;

  var columnWidth = $table.find('th').outerWidth();
  var maxOffset = columnWidth * getDimensionColumns() - $table.outerWidth();

  $(window).resize(function () {
    columnWidth = $table.find('th').outerWidth();
    maxOffset = columnWidth * getDimensionColumns() - $table.outerWidth();
  });

  function getDimensionColumns() {
    var columns = 4;
    if ($(window).width() <= _globals.TABLET_WIDTH) {
      columns = 3;
    } else if ($(window).width() <= _globals.LANDSCAPE_MOBILE_WIDTH) {
      columns = 2;
    }
    return columns;
  }

  function checkForDisabled() {
    var currentOffset = parseInt($table.css('left')) || 0;
    if (currentOffset + columnWidth > 0) {
      $('.js-compare-prev').addClass('is-disabled');
    } else {
      $('.js-compare-prev').removeClass('is-disabled');
    }
    if (currentOffset - columnWidth < maxOffset) {
      $('.js-compare-next').addClass('is-disabled');
    } else {
      $('.js-compare-next').removeClass('is-disabled');
    }
  }

  checkForDisabled();

  $('.js-compare-prev').click(function () {
    var $button = $(this);
    if ($button.hasClass('is-busy')) return;
    var offset = $table.css('left') ? parseInt($table.css('left')) + columnWidth : columnWidth;
    if (offset <= 0) {
      $table.css('left', offset);
    }
    $button.addClass('is-busy');
    setTimeout(function () {
      checkForDisabled();
      $button.removeClass('is-busy');
    }, 600);
  });

  $('.js-compare-next').click(function () {
    var $button = $(this);
    if ($button.hasClass('is-busy')) return;
    var offset = $table.css('left') ? parseInt($table.css('left')) - columnWidth : -columnWidth;
    if (offset >= maxOffset) {
      $table.css('left', offset);
    }
    $button.addClass('is-busy');
    setTimeout(function () {
      checkForDisabled();
      $button.removeClass('is-busy');
    }, 600);
  });

  if (_globals.IS_TOUCH_DEVICE) {
    var hammerobject = new Hammer($table[0], {});
    hammerobject.on('swipeleft', function (e) {
      $('.js-compare-next').click();
    });
    hammerobject.on('swiperight', function (e) {
      $('.js-compare-prev').click();
    });
  }

  $('.js-params-toggler').change(function () {
    if ($(this).prop('checked')) {
      $table.find('tr:not(:first-child)').each(function () {
        var $row = $(this);
        var params = $.map($row.find('td'), function (item) {
          return $(item).text();
        });
        if (!arrayHasUniqueValues(params)) {
          $row.hide();
        }
      });
    } else {
      $table.find('tr').show();
    }
  });

  function arrayHasUniqueValues(array) {
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    var unique = array.filter(onlyUnique);

    return unique.length != 1;
  }
})();

},{"./globals":8}],6:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.js-init-later)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true,
    optionsItemBuilder: function optionsItemBuilder(itemData, element, index) {
      return !element ? itemData.text : element.val().length ? '<span class="ico ico-' + element.val() + '"></span>' + itemData.text : itemData.text;
    },
    labelBuilder: function labelBuilder(currItem) {
      return (currItem.value.length ? '<span class="ico ico-' + currItem.value + '"></span>' : '') + currItem.text;
    }
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }

  if ($inp.prop('disabled')) {
    $label.addClass('is-disabled');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  console.log(event);
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

$('.js-select-service').click(function () {
  var service = $(this).data('service');
  var $select = $('.js-services-list');

  $select.find('option[value="' + service + '"]').prop('selected', true).change();
  $select.selectric('refresh');
});

(function () {

  if (!$('.js-toggle-mobile-filters').length) return;

  var opened_class = 'filter-is-opened';

  var url = new URL(window.location);
  if (url.searchParams.get('filter')) {
    _globals.$BODY.addClass(opened_class);
  }

  $(document).on('click', '.js-toggle-mobile-filters', function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(opened_class);

    if (_globals.$BODY.hasClass(opened_class)) {
      var _url = new URL(window.location);
      _url.searchParams.set('filter', 'opened');
      window.history.pushState({}, '', _url);
    } else {
      var _url2 = new URL(window.location);
      if (_url2.searchParams.get('filter')) {
        // window.history.back();
        _url2.searchParams.delete('filter');
        window.history.pushState({}, '', _url2);
      }
    }
  });

  window.addEventListener('popstate', onPopstate);

  function onPopstate(event) {
    var url = new URL(window.location);
    if (!url.searchParams.get('filter')) {
      _globals.$BODY.removeClass(opened_class);
    } else {
      _globals.$BODY.addClass(opened_class);
    }
  };
})();

// Touched inputs state
// --------------------

$('.form__row').find('.inp, .textarea').on('focus', function () {
  $(this).closest('.form__row').addClass('is-touched is-focused');
}).on('blur', function () {
  var $this = $(this);
  var value = $this.val();

  $this.closest('.form__row').removeClass('is-focused');

  if (value != '' && value != null) return;

  $this.closest('.form__row').removeClass('is-touched');
}).on('inputs-init', function () {
  var $this = $(this);
  var value = $this.val();

  if (value != '' && value != null) {
    $this.closest('.form__row').addClass('is-touched');
  }
}).trigger('inputs-init');

},{"./globals.js":8}],7:[function(require,module,exports){
"use strict";

},{}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// Custom vh for mobile devices
// ----------------------------

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

// Trigger fonts loaded event
// --------------------------

if (document.fonts != undefined) {
  document.fonts.onloadingdone = function () {
    $(document).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":1}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":8}],10:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer == '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],11:[function(require,module,exports){
'use strict';

$('.js-mosaic-reviews').each(function () {
  $(this).isotope({
    itemSelector: '.item',
    percentPosition: true,
    masonry: {
      columnWidth: '.grid-sizer',
      gutter: 30
    }
  });
});

},{}],12:[function(require,module,exports){
'use strict';

(function () {

  $('input[type="tel"]').each(function () {

    var $input = $(this);
    var defaultPlaceholder = $input.attr('placeholder');
    var defaultPlaceholderMask = '+7 (___) ___-__-__';
    var phoneMask = '+7 (000) 000-00-00';
    var pasted = false;

    $input.on('focus', function () {
      $input.attr('placeholder', defaultPlaceholderMask);
    }).on('blur', function () {
      $input.attr('placeholder', defaultPlaceholder);
    });

    $input.on('paste drop', function () {
      var isEmpty = !$input.val();
      var element = $input.get(0);
      var selectionLength = element.selectionEnd - element.selectionStart;

      if (!isEmpty && $input.val().length !== selectionLength) {
        return;
      }

      pasted = true;
    });

    $input.on('input', function (e) {
      var value = $input.val();

      if (value.startsWith('8')) {
        if (pasted && value.length !== 11) {
          pasted = false;
          return;
        }

        pasted = false;

        e.stopPropagation();

        value = value.replace('8', '+7');
        $input.val(value);
      }
    });

    $input.mask(phoneMask);
  });
})();

},{}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown && link) {
      location.href = $anchor.attr('href');
    } else if (!has_dropdown && !link) {
      $('.js-toggle-mobile-menu').click();
    } else {
      if (is_hovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    }
  });
}

(function () {

  if (!$('#mobile-menu').length) return;

  var opened_class = 'mobile-menu-is-opened';

  $('.js-toggle-mobile-menu').click(function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(opened_class);

    $('#mobile-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(opened_class)) {
      var url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      _globals.$BODY.on('click.close-menu', function (e) {
        var $target = $(e.target);

        if ($target.closest('.mobile-menu').length || $target.hasClass('js-toggle-mobile-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');

    var url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate(event) {
    if (_globals.$BODY.hasClass(opened_class)) {
      closeMenu(false);
    }
  };

  var hammerobject = new Hammer($('.mobile-menu')[0], {});
  hammerobject.on('swipeleft', function (e) {
    if (_globals.$BODY.hasClass(opened_class)) {
      closeMenu(true);
    }
  });
})();

(function () {
  var opened_class = 'menu-is-opened';

  $('.js-toggle-header-menu').click(function (e) {
    e.preventDefault();

    _globals.$BODY.toggleClass(opened_class);

    if (_globals.$BODY.hasClass(opened_class)) {
      addCloseHandler();
    } else {
      _globals.$BODY.removeClass(opened_class);
      _globals.$BODY.off('click.close-header-menu');
    }
  });

  if (_globals.$BODY.hasClass(opened_class)) {
    addCloseHandler();
  }

  function addCloseHandler() {
    _globals.$BODY.on('click.close-header-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.header-catalogue').length || $target.hasClass('js-toggle-header-menu')) {
        e.stopPropagation();
        return;
      }

      _globals.$BODY.removeClass(opened_class);
      _globals.$BODY.off('click.close-header-menu');
    });
  }
})();

$(document).on('click', '.js-menu-back', function () {
  $(this).closest('.has-dropdown').removeClass('is-hovered');
});

$('.js-toggle-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  $(this).closest('.has-dropdown').toggleClass('is-hovered');
});

$('.js-mobile-search .btn').click(function () {
  $(this).closest('form').submit();
});

$('.js-toggle-mobile-search').click(function (e) {
  e.preventDefault();

  var opened_class = 'search-is-opened';

  _globals.$BODY.toggleClass(opened_class);

  if (_globals.$BODY.hasClass(opened_class)) {
    $('.js-header-search .inp').focus();
    _globals.$BODY.on('click.close-header-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.js-header-search').length || $target.hasClass('js-toggle-mobile-search')) {
        e.stopPropagation();
        return;
      }

      _globals.$BODY.removeClass(opened_class);
      _globals.$BODY.off('click.close-header-menu');
    });
  } else {
    _globals.$BODY.removeClass(opened_class);
    _globals.$BODY.off('click.close-header-menu');
  }
});

},{"./globals.js":8}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });
});

(function () {

  $('.js-close-popup-by-swipe').each(function () {
    _globals.$DOCUMENT.hammer({ domEvents: true }).on('pandown', '.js-close-popup-by-swipe', function () {
      $.overlayLoader(false, $(this).closest('.popup'));
    });
  });
})();

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function (e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $self.attr('href') ? $($self.attr('href')) : $($self.data('href'));

  $.overlayLoader(true, {
    node: $node,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: _parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').html(data);
      // window.initPriceApp('#js-card-price-app-details');
      initPriceApp('#' + $node.find('.js-card-price-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error(data) {}
  });
});

function _parseAjaxOverlayParams(str) {

  if (str == undefined || str == '') return {};

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var _a = value.split('=');
    obj[_a[0]] = _a[1];
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Tooltips
$(function () {
  // $(document).on('click', '.text-hint, .hint-anchor', function(e) {
  //   e.stopPropagation();

  //   if (IS_TOUCH_DEVICE) {
  //     $(this).toggleClass('is-hovered');
  //   }
  // });

  // if (IS_TOUCH_DEVICE) {
  //   $(document).on('click', '.text-hint .hint-content, .hint-anchor .hint-content', function(e) {
  //     e.stopPropagation();
  //   });
  // }

  // if (!IS_TOUCH_DEVICE) {
  tippy.delegate('body', {
    target: '.text-hint, .hint-anchor',
    appendTo: function appendTo() {
      return document.body;
    },
    // trigger: 'click',
    content: function content(reference) {
      return $(reference).find('.text-hint__content').length ? $(reference).find('.text-hint__content').clone().html() : $(reference).find('.hint-content').clone().html();
    },
    allowHTML: true,
    interactive: true,
    touch: true,
    onTrigger: function onTrigger(instance, event) {
      event.stopPropagation();
    },
    onUntrigger: function onUntrigger(instance, event) {
      event.stopPropagation();
    },

    popperOptions: {
      strategy: 'fixed'
      // modifiers: [
      //   {
      //     name: 'flip',
      //     options: {
      //       fallbackPlacements: ['bottom', 'right'],
      //     },
      //   },
      //   {
      //     name: 'preventOverflow',
      //     options: {
      //       altAxis: true,
      //       tether: false,
      //     },
      //   },
      // ],
    }
  });
  // }

  tippy.delegate('body', {
    target: '.color-hint-anchor',
    appendTo: function appendTo() {
      return document.body;
    },
    content: function content(reference) {
      return $(reference).find('.text-hint__content').length ? $(reference).find('.text-hint__content').clone().html() : $(reference).find('.hint-content').clone().html();
    },
    allowHTML: true,
    interactive: true,
    touch: false,
    onTrigger: function onTrigger(instance, event) {
      event.stopPropagation();
    },
    onUntrigger: function onUntrigger(instance, event) {
      event.stopPropagation();
    },

    popperOptions: {
      strategy: 'fixed'
    }
  });
});

},{"./globals.js":8}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

var $orderAside = $('.js-order-fixed-panel');

if ($orderAside.length && !(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
  var PANEL_OFFSET_TOP = _helpers.IS_TABLET_WIDTH ? 5 + $('.header').height() : 25;
  var PANEL_OFFSET_BOTTOM = 25;
  var $container = $('.order-page__content');
  var asideHeight = $orderAside.outerHeight() + PANEL_OFFSET_BOTTOM;
  var asidePosition = $orderAside.offset().top;
  var bottomLimit = $container.offset().top + $container.height();
  var maxOffset = bottomLimit - asideHeight;

  _globals.$WINDOW.on('resize basketHeightChange', function () {
    asideHeight = $orderAside.outerHeight() + PANEL_OFFSET_BOTTOM;
    asidePosition = $orderAside.offset().top;
    bottomLimit = $container.offset().top + $container.height();
    maxOffset = bottomLimit - asideHeight;
  });

  _globals.$WINDOW.on('scroll.order-page-scroll', function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    var offset = scroll_top - asidePosition + PANEL_OFFSET_TOP;

    if (offset < 0) {
      $orderAside.css('top', 0);
    } else if (offset >= 0) {
      if (scroll_top < maxOffset - PANEL_OFFSET_TOP) {
        $orderAside.css('top', offset);
      } else {
        $orderAside.css('top', maxOffset - asidePosition);
      }
    }
  });
}

$('.js-copy').click(function () {
  var $block = $(this).find('.js-code');
  var text = $block.text();
  copyToClipboard(text);
});

function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return clipboardData.setData('Text', text);
  } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    var textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand('copy'); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

(function () {
  var $promocodePopup = $('.js-promocode-float');

  if (!$promocodePopup.length) return;

  var timeToShow = $promocodePopup.data('inactiveTime') * 1000;
  var $timer = $('.js-promocode-timer');
  var $minutes = $('.js-promocode-timer-minutes');
  var $seconds = $('.js-promocode-timer-seconds');
  var startTime = $timer.data('timer');
  var seconds = void 0;
  var minutes = void 0;
  var countdownFunction = void 0;
  var timerFunction = void 0;

  function initTimer() {
    seconds = startTime % 60;
    minutes = (startTime - seconds) / 60;
    setTime();
  }

  function setTime() {
    $minutes.text(toTwoDigits(minutes));
    $seconds.text(toTwoDigits(seconds));
  }

  function toTwoDigits(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  function startTimer() {
    countdownFunction = setInterval(function () {
      seconds -= 1;
      if (seconds < 0) {
        seconds = 59;
        minutes -= 1;
      }
      if (minutes == 0 && seconds == 0) {
        stopTimer();
      }
      setTime();
    }, 1000);
  }

  function stopTimer() {
    clearInterval(countdownFunction);
  }

  initTimer();

  function setShowTime() {
    timerFunction = setTimeout(function () {
      $promocodePopup.fadeIn();
      startTimer();
      $(document).off('click.timer keypress.timer');
    }, timeToShow);
  }
  setShowTime();

  $(document).on('click.timer keypress.timer', function () {
    clearTimeout(timerFunction);
    setShowTime();
  });

  $('.js-close-promocode-float').click(function () {
    $promocodePopup.stop(true, true).fadeOut();
  });
})();

},{"./globals.js":8,"./helpers.js":9}],16:[function(require,module,exports){
'use strict';

(function () {
  if (!$('#js-quiz-widget').length) return;

  var vm = new Vue({
    el: '#js-quiz-widget',
    data: function data() {
      var data = window.quizData;
      return {
        currentQuestion: 1,
        questionsCounter: data.questions.length + 1,
        formSended: false,
        quiz: data,
        products: [],
        formShowFlag: false,
        quizCompleted: false,
        showProducts: false
      };
    },

    computed: {
      progressPercentStyle: function progressPercentStyle() {
        return {
          'width': this.currentQuestion * 100 / this.questionsCounter + '%'
        };
      },
      quizFindProduct: function quizFindProduct() {
        return this.currentQuestion == this.questionsCounter;
      },
      isFormShow: function isFormShow() {
        return this.formShowFlag && !this.formSended;
      },
      showSpecialLabel: function showSpecialLabel() {
        if (this.quiz.questions[this.currentQuestion - 1] === undefined) return false;

        return this.quiz.questions[this.currentQuestion - 1].specialLabel !== undefined ? this.quiz.questions[this.currentQuestion - 1].specialLabel : false;
      }
    },
    methods: {
      fillForm: function fillForm() {
        var textResult = [];
        var findCondition = {};
        var self = this;
        $.each($.deparam($('.quiz-widget__form').serialize()), function (key, value) {
          if (key != "products") {
            var question = self.findField(self.quiz.questions, 'id', key);
            var answer = self.findField(question.answers, 'id', value);
            textResult.push(question.question + ': ' + (value == "" ? 'ответ не выбран' : answer.text));
            if (value != "") {
              if (question.parameter !== undefined && answer.variant !== undefined) findCondition[question.parameter] = answer.variant;
            }
          }
        });

        var products = [];
        $.each($.deparam($('.quiz-widget__form').serialize())['products'], function (key) {
          products[key] = self.products[key];
        });

        $('#argilla_modules_quiz_models_QuizForm_result').val(textResult.join('\n'));
        $('#argilla_modules_quiz_models_QuizForm_products').val(JSON.stringify(products));

        return JSON.stringify(findCondition);
      },
      findField: function findField(array, field, value) {
        return array.find(function (element, index) {
          return element[field] == value;
        });
      },
      answerClass: function answerClass(baseClass, item) {
        if (item.images) baseClass += ' quiz-widget__variants--images';
        if (item.cssClass !== undefined) baseClass += ' ' + item.cssClass;
        return baseClass;
      },
      decodeBase64: function decodeBase64(str) {
        return atob(str);
      },
      prev: function prev() {
        if (vm.currentQuestion > 1) {
          vm.currentQuestion -= 1;
          this.formShowFlag = false;
          this.showProducts = false;
          this.quizCompleted = false;
        }
        $(window).scrollTo(0, 200);
        setTimeout(function () {
          vm.updateVitrineScrollbar();
        }, 200);
      },
      next: function next() {
        vm.currentQuestion += 1;
        if (vm.quizFindProduct) {
          var conditions = this.fillForm();
          var self = this;
          $.mouseLoader(true);
          $.post('quiz_products', { 'conditions': conditions }, function (result) {
            self.products = result.products;
            if (result.products.length > 0) {
              self.formShowFlag = false;
              self.showProducts = true;
            } else {
              self.formShowFlag = true;
              self.showProducts = false;
              self.quizCompleted = true;
            }

            $.mouseLoader(false);
          });
        } else if (this.currentQuestion > this.questionsCounter) {
          this.formShowFlag = true;
          this.showProducts = false;
          this.quizCompleted = true;
          this.fillForm();
        } else {
          this.formShowFlag = false;
          this.showProducts = false;
        }
        $(window).scrollTo(0, 200);
        setTimeout(function () {
          vm.updateVitrineScrollbar();
        }, 200);
      },
      reset: function reset() {
        vm.currentQuestion = 1;
        vm.formSended = false;
        vm.quizCompleted = false;
        vm.formShowFlag = false;
        vm.quizCompleted = false;
        vm.showProducts = false;
        vm.products = [];
        $(this.$el).find('input[type="radio"], input[type="checkbox"]').each(function () {
          $(this).prop('checked', false).trigger('change');
        });
      },
      updateVitrineScrollbar: function updateVitrineScrollbar() {
        if ($(window).width() > 812) {
          $(this.$el).find('.js-scrollbar').data('ps').update();
        }
      }
    },
    mounted: function mounted() {
      //this.questionsCounter = $(this.$el).find('fieldset').length;
      /*      $('.js-quiz-feedback-form').submit(function(e) {
              e.preventDefault();
              vm.formSended = true;
            });*/
      document.addEventListener('DOMContentLoaded', function () {
        argilla.event.attachEventHandler('quizFromSubmit', function (data) {
          if (data.result === "quizSentSuccess") {
            vm.formSended = true;
          }
        });
      });

      $(this.$el).find('.js-scrollbar').each(function () {
        $(this).find('.ps__rail-x, .ps__rail-y').remove();
        if ($(window).width() > 812) {
          var ps = new PerfectScrollbar($(this)[0], {
            suppressScrollX: true
          });

          $(this).data('ps', ps);

          $(window).resize(function () {
            ps.update();
          });
        }
      });
    }
  });
})();

},{}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elem_to_scroll = $($lnk.attr('href'));
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || -$('.header').outerHeight();

  if ($(this).hasClass('js-chars-tab__link')) {
    $('.js-tabs').each(function () {
      $(this).find('.tabs-nav li:nth-child(2) a').click();
    });
  };

  _globals.$WINDOW.scrollTo($elem_to_scroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $go_top_btn = $('<div class="go-top-btn"></div>');

  $go_top_btn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    if (scroll_top > 0) {
      $go_top_btn.addClass('is-visible');
    } else {
      $go_top_btn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($go_top_btn);
}

// Scroll direction
// ----------------

var lastScrollPosition = $(window).scrollTop();
$(window).scroll(_.throttle(function () {
  var scrollPosition = $(window).scrollTop();

  if (scrollPosition > 0) {
    $('body').addClass('scrolled');
  } else {
    $('body').removeClass('scrolled');
  }

  if (lastScrollPosition < scrollPosition) {
    $('body').addClass('scrolled-down').removeClass('scrolled-up');
  } else {
    $('body').addClass('scrolled-up').removeClass('scrolled-down');
  }

  lastScrollPosition = scrollPosition;
}, 300));

// Custom scrollbar
// ----------------

$('.js-scrollbar').each(function () {
  var ps = new PerfectScrollbar($(this)[0], {
    suppressScrollX: true
  });

  $(this).data('ps', ps);

  $(window).resize(function () {
    ps.update();
  });
});

},{"./globals.js":8,"./helpers.js":9}],18:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$('.js-homepage-slider').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 500,
    dots: true,
    arrows: true,
    appendArrows: '.homepage-slider__controls',
    appendDots: '.homepage-slider__controls',
    pauseOnHover: false,
    useTransform: false
  });
});

$('.js-slideshow').each(function () {
  var $this = $(this);
  var $controls = $this.parent().find('.js-slider-controls');

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    appendArrows: $controls,
    appendDots: $controls,
    pauseOnHover: false
  });
});

$(function () {
  $(window).on('sliders-init resize', function () {

    $('.js-mobile-features-slider, .js-mobile-reviews-slider, .js-mobile-vitrine-slider, .js-mobile-news-slider, .js-mobile-gallery-slider').each(function () {
      var $this = $(this);
      var isVitrine = $this.hasClass('js-mobile-vitrine-slider');

      if ($(window).width() > _globals.SMALL_MOBILE_WIDTH) {
        if (!$this.hasClass('slick-initialized')) return;

        $this.slick('unslick');
      } else {
        $this.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
          useTransform: isVitrine ? false : true
        });
      }
    });

    $('.js-brands-slider').each(function () {
      var $this = $(this);

      if ($(window).width() <= _globals.SMALL_MOBILE_WIDTH) {
        if (!$this.hasClass('slick-initialized')) return;

        $this.slick('unslick');
      } else {
        if (!$this.hasClass('slick-initialized')) {
          $this.slick({
            rows: 2,
            slidesPerRow: 7,
            infinite: false,
            arrows: true,
            dots: false,
            responsive: [{
              breakpoint: _globals.TABLET_WIDTH + 1,
              settings: {
                slidesPerRow: 4
              }
            }, {
              breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
              settings: 'unslick'
            }]
          });
        }
      }
    });

    $('.js-gallery-thumbs').each(function () {
      var $this = $(this);
      var $connected = $this.closest('section').find('.js-gallery-slider');

      if ($this.hasClass('slick-initialized')) return;

      $this.slick({
        infinite: false,
        arrows: false,
        dots: false,
        asNavFor: $connected,
        focusOnSelect: true
      });
    });

    $('.js-gallery-slider').each(function () {
      var $this = $(this);
      var $connected = $this.closest('section').find('.js-gallery-thumbs');

      if ($this.hasClass('slick-initialized')) return;

      $this.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: false,
        asNavFor: $connected,
        adaptiveHeight: true,
        responsive: [{
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: {
            dots: true
          }
        }]
      });
    });

    $('.js-content-gallery-slider').each(function () {
      var $this = $(this);
      var $parent = $this.parent();

      if ($this.hasClass('slick-initialized')) return;

      $this.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        arrows: true,
        appendArrows: $parent,
        responsive: [{
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: 'unslick'
        }]
      });
    });
  }).trigger('sliders-init');
});

},{"./globals.js":8}],19:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

function fixMinVal(value) {
  var min_value = $('.js-spinner-calc .inp').data('min-value');
  if (value < min_value) value = min_value;

  return value;
}

_globals.$DOCUMENT.on('mousedown.js-spinner-calc', '.js-spinner-calc-down, .js-spinner-calc-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var min_value = $input.data('min-value') ? $input.data('min-value') : 1;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner-calc', clearAll).on('mouseleave.js-spinner-calc', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-calc-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-calc-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change().trigger('input');
    $input[0].dispatchEvent(new Event('input'));
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    } else {
      if (value > step) {
        value = value - step;
        value = fixMinVal(value);
        $input.val(value).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner-calc .inp', function (e) {
  var $input = $(this);
  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner-calc .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  //$input.val().change();

  /*  if ($input.val() == 0 || $input.val() == '') {
      if (!may_be_zero) {
        $input.val(1).change();
      } else {
        $input.val(0).change();
      }
    }*/
});

_globals.$DOCUMENT.on('focusout mouseout', '.js-spinner-calc .inp', function (e) {
  var valBefore = $('.js-spinner-calc .inp').val();
  var value = fixMinVal(valBefore);
  if (valBefore != value) {
    $('.js-spinner-calc .inp').val(value).change();
    $('.js-spinner-calc .inp').get(0).dispatchEvent(new Event('input'));
  }
});

},{"./globals.js":8}],20:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change().trigger('input');
    $input[0].dispatchEvent(new Event('input'));
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
        $input[0].dispatchEvent(new Event('input'));
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1).change();
    } else {
      $input.val(0).change();
    }
  }
});

$(function () {
  var $this = $('.js-catalogue-text-toggler');

  if ($this.next().children('*').length < 2) {
    $this.hide();
  }

  if ($this.parent().children('*').length < 3) {
    $this.parent().addClass('no--logo');
  }

  $(document).on('click', '.js-catalogue-text-toggler', function (e) {
    e.preventDefault();

    $this.toggleClass('is-expanded');
  });
});

},{"./globals.js":8}],21:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$(function () {

  var initialWindowWidth = $(window).width();

  $('.js-spoiler').each(function () {
    var $this = $(this);
    $(window).on('spoilersInit', function () {
      initSpoilers($this);
    });
    $(window).on('resize', function () {
      var windowWidth = $(window).width();
      // prevent resize firing when address bar appears/disappears on mobiles
      if (windowWidth != initialWindowWidth) {
        initialWindowWidth = windowWidth;
        initSpoilers($this);
      }
    });
    $(document).on('fontsLoaded', function () {
      initSpoilers($this);
    });
    $this.find('img').each(function () {
      var image = $(this)[0];
      image.addEventListener('load', function () {
        setTimeout(function () {
          initSpoilers($this);
        }, 200);
      });
    });
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  function initSpoilers($this) {
    var isExpanded = $this.hasClass('is-expanded');
    var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
    var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
    var isInsideFilter = $this.closest('.filter').length;
    var $el = $this.find('.js-spoiler-body').first();
    var el = $el[0];
    $('.js-spoiler-body').each(function () {
      $(this)[0].style.setProperty('transition', 'none');
      $(this).find('ul').addClass('is-expanded-for-calc');
    });
    if (!isExpanded) $this.addClass('is-expanded-for-calc');
    if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
    if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
    if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', 'block');
    el.style.setProperty('--max-height', '');
    el.style.setProperty('--max-height', $el.outerHeight() + 'px');
    if (!isExpanded) $this.removeClass('is-expanded-for-calc');
    if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
    if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
    if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', '');
    $('.js-spoiler-body').each(function () {
      $(this)[0].style.setProperty('transition', '');
      $(this).find('ul').removeClass('is-expanded-for-calc');
    });
  }

  $(document).on('click', '.js-toggle-spoiler', function () {
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $('.js-toggle-mobile-spoiler').click(function () {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $(document).on('click', '.js-toggle-share-socials', function () {
    var $shareBlock = $(this).parent();
    $shareBlock.toggleClass('is-active');
    if ($shareBlock.hasClass('is-active')) {
      $('body').on('click.close-sharing', function (e) {
        var $target = $(e.target);
        if ($target.closest('.js-share-block').length) {
          e.stopPropagation();
          return false;
        }
        $shareBlock.removeClass('is-active');
        $('body').off('click.close-sharing');
      });
    } else {
      $shareBlock.removeClass('is-active');
      $('body').off('click.close-sharing');
    }
  });

  $(document).on('click', '.js-toggle-filter-list', function (e) {
    e.preventDefault();
    e.stopPropagation();

    $(this).parent().find('ul').toggleClass('is-expanded');
  });

  $('.js-toggle-all-params').click(function (e) {
    e.preventDefault();

    $(this).prev().toggleClass('is-expanded');
  });

  $(document).on('click', '.js-toggle-mobile-description', function (e) {
    e.preventDefault();

    $(this).parent().toggleClass('is-expanded');
  });
});

},{"./helpers":9}],22:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function () {
    $(this).find('.tabs-nav li:first a').click();
  });
});

},{}],23:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  data: function data() {
    return {
      expanded: false
    };
  },

  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      classArray.push('js-offer-id-' + offer.id);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    priceBase: { // цена за 1 единицу товара
      type: Number
    },
    oldPriceBase: { // старая цена за 1 единицу товара
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call" style="white-space: normal">По запросу</span>'
    },
    rate: { // мин едеиниц (цена задана ни мин единицу)
      type: Number,
      default: 1
    },
    minAmount: { // Минимальное число допустимых для заказа единиц
      default: 1
    }
  },
  data: function data() {
    return {
      amount: this.minAmount,
      rateInner: this.rate * this.minAmount,
      reserve: 0,
      reserveRateBackup: 0
    };
  },

  computed: {
    price: function price() {
      return this.round(this.priceBase * this.rate);
    },
    oldPrice: function oldPrice() {
      return this.round(this.oldPriceBase * this.rate);
    },
    priceSum: function priceSum() {
      return this.round(this.priceBase * this.rate * this.getAmount());
    },
    oldPriceSum: function oldPriceSum() {
      return this.round(this.oldPriceBase * this.rate * this.getAmount());
    },
    economySum: function economySum() {
      if (this.oldPriceSum > 0 && this.oldPriceSum > this.priceSum) return this.oldPriceSum - this.priceSum;

      return 0;
    },

    rateCalc: {
      get: function get() {
        return isNaN(this.rateInner) ? "" : this.rateInner;
      },
      set: function set(newValue) {
        this.setRate(newValue);
        //this.resetReserve();
      }
    },
    amountInput: {
      get: function get() {
        return this.amount;
      },
      set: function set(newValue) {
        this.amount = newValue;
        this.rateInner = this.smartRound(this.getAmount() * this.rate);
        //this.resetReserve();
      }
    },
    needUse: function needUse() {
      return this.smartRound(this.amount * this.rate);
    }
    /*    economyPercent() {
          if( !this.oldPrice )
            return 0;
          var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
          return Math.ceil(percent);
        },*/

  },
  watch: {
    /*    reserve() {
          if( this.reserveRateBackup == 0 )
            this.reserveRateBackup = this.rateCalc;
    
          if( this.reserve == 0 && this.reserveRateBackup > 0 ) {
            this.setRate(this.reserveRateBackup)
            this.reserveRateBackup = 0;
          }
          else if(this.reserve > 0) {
           let result = (this.reserveRateBackup + (parseFloat(this.reserveRateBackup) * parseFloat(this.reserve))).toPrecision(3);
            this.setRate(result);
          }
        },*/
  },
  methods: {
    smartRound: function smartRound(val) {
      if (String(val).indexOf('.') !== -1) {
        val = val.toFixed(2);
        val = String(val).replace(/0$/, '');
        val = Number(val.replace(/.0$/, ''));
      }
      return val;
    },
    getAmount: function getAmount() {
      return this.amount < this.minAmount ? parseFloat(this.minAmount) : this.amount;
    },
    setRate: function setRate(rate) {
      this.rateInner = parseFloat(rate);
      this.calcAmount(this.rateCalc);
    },
    resetReserve: function resetReserve() {
      this.reserveRateBackup = 0;
      //$('.js-init-later').val(0).change().selectric("refresh");
    },
    calcAmount: function calcAmount(inputRate) {
      var val = Math.ceil(inputRate / this.rate);
      this.amount = val < this.minAmount ? parseFloat(this.minAmount) : val;

      setTimeout(function () {
        $('.amount-basket').change();
      }, 150);
    },
    format: function format(price) {
      if (price > 0) return price.toLocaleString('ru-RU') + this.currencySuffix;

      return this.emptyPriceText;
    },
    formatPercent: function formatPercent(percent) {
      return percent + '%';
    },
    round: function round(a, b) {
      b = b || 0;
      return Math.round(a * Math.pow(10, b)) / Math.pow(10, b);
    }
  },
  mounted: function mounted() {
    var vm = this;
    this.$nextTick(function () {
      $(this.$el).find('select.js-init-later').selectric({
        disableOnMobile: false,
        nativeOnMobile: true
      }).on('change', function () {
        //$('.js-cart-basket-button').on('input'); // запускаем цепочку обновления
        //vm.reserve = $(this).val();
      });
    });
  }
};

exports.default = PriceComponent;

},{}]},{},[2]);
